<template>
  <button
    class="button is-primary"
    type="button"
    slot="trigger"
    v-bind:style="{ 'min-width': minWidth }"
  >
    <b-loading
      active
      :is-full-page="false"
      :can-cancel="true"
    />
  </button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DisabledButton',
  props: {
    minWidth: {
      type: String,
      default: '20rem'
    }
  },
  computed: {
    ...mapGetters(['loading'])
  }
}
</script>

<style scoped></style>
