<template>
  <div>
    <hero-bar :has-right-visible="true" v-if="scaleForm">
      <span v-if="!fieldEditable['title']">{{ scaleForm.title }}</span>

      <b-field label="Scale title" v-else width="100%">
        <b-input v-model="scaleForm.title" expanded></b-input>
      </b-field>

      <div slot="right">
        <toggle-edit-button
          :unlocked="fieldEditable['title']"
          @toggle-lock="toggleEdit('title')"
        />
      </div>
    </hero-bar>
    <hero-bar :has-right-visible="false" v-else>
      <b-skeleton size="is-large" width="350"/>
    </hero-bar>

    <section class="section">
      <tiles>
        <card-component title="Overview" icon="glasses" class="tile is-child">
          <div class="columns">
            <div class="column is-one-quarter has-text-weight-bold">
              Created by:
            </div>
            <div class="column">
              <span v-if="scaleForm">{{ scaleForm.created_by }}</span>
              <b-skeleton :width="r100()" active v-else/>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-quarter has-text-weight-bold">
              Created:
            </div>
            <div class="column">
              <span v-if="scaleForm">{{ scaleForm.created_at }}</span>
              <b-skeleton :width="r100()" active v-else/>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-quarter has-text-weight-bold">
              Modified:
            </div>
            <div class="column">
              <span v-if="scaleForm">{{ scaleForm.updated_at }}</span>
              <b-skeleton :width="r100()" active v-else/>
            </div>
          </div>
        </card-component>
        <card-component
          title="Edit"
          icon="square-edit-outline"
          class="tile is-child"
        >
          <!-- Category Field-->
          <b-field label="Category" v-if="scaleForm">
            <b-field>
              <div v-if="fieldEditable['category_id']">
                <b-dropdown v-if="loading">
                  <loading-button min-width="20 rem"></loading-button>
                </b-dropdown>
                <b-dropdown
                  aria-role="list"
                  v-else
                  v-model="scaleForm.category[0]"
                  @change="updateCategorySelect(0, $event)"
                >
                  <button class="button" slot="trigger" slot-scope="{ active }">
                    <span>{{ scaleForm.category[0].name }}</span>
                    <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                  </button>
                  <b-dropdown-item
                    aria-role="listitem"
                    v-for="(c, idx) in scale.allCategories"
                    :key="idx"
                    :value="c"
                  >
                    {{ c.name }}
                  </b-dropdown-item>
                </b-dropdown>

                <b-icon icon="chevron-right"></b-icon>

                <b-dropdown v-if="!scaleForm">
                  <loading-button min-width="20 rem"></loading-button>
                </b-dropdown>
                <b-dropdown
                  aria-role="list"
                  v-else
                  v-model="scaleForm.category[1]"
                  @change="updateCategorySelect(1, $event)"
                >
                  <button class="button" slot="trigger" slot-scope="{ active }">
                    <span>{{ scaleForm.category[1].name }}</span>
                    <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                  </button>
                  <b-dropdown-item
                    aria-role="listitem"
                    v-for="(c, idx) in categoryData.children_list"
                    :key="idx"
                    :value="c"
                  >
                    {{ c.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>

              <!-- when disabled, just show the values in two disabled imnput fields -->
              <b-field grouped v-else>
                <b-input expanded disabled :value="scaleForm.category[0].name"/>
                <b-icon icon="chevron-right"></b-icon>
                <b-input expanded disabled :value="scaleForm.category[1].name"/>
              </b-field>
              <toggle-edit-button
                :unlocked="fieldEditable['category_id']"
                @toggle-lock="toggleEdit('category_id')"
              />
            </b-field>
          </b-field>

          <!-- Authors Field-->
          <editable-input-field
            label="Authors"
            :form="scaleForm"
            fieldName="authors"
            :endpoint="scale_endpoint"
            icon="account-group"
            v-if="scaleForm"
            expanded
          />

          <!-- Status Field-->
          <b-field grouped>
            <b-field label="Status" v-if="scaleForm" expanded>
              <b-field>
                <b-input
                  expanded
                  v-show="!fieldEditable['status']"
                  disabled
                  v-model="scaleForm.status"
                  icon="pencil"
                ></b-input>
                <b-select
                  placeholder="Select a status"
                  expanded
                  v-show="fieldEditable['status']"
                >
                  <option value="draft">draft</option>
                  <option value="active" selected>active</option>
                </b-select>

                <p class="control">
                  <toggle-edit-button
                    :unlocked="fieldEditable['status']"
                    @toggle-lock="toggleEdit('status')"
                  />
                </p>
              </b-field>
            </b-field>
            <!-- Visibility Field-->
            <b-field
              label="Visibility"
              v-if="scaleForm"
              class="bl-4"
              style="border-left: 1.5rem"
            >
              <b-field>
                <b-checkbox
                  v-model="checkBoxPrivate"
                  true-value="Private"
                  false-value="Public"
                  :disabled="!fieldEditable['visibility']"
                >
                  {{ checkBoxPrivate }}
                </b-checkbox>
                <toggle-edit-button
                  :unlocked="fieldEditable['visibility']"
                  @toggle-lock="toggleEdit('visibility')"
                />
              </b-field>
            </b-field>
          </b-field>
        </card-component>
      </tiles>
    </section>
    <section class="section">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="ruler" custom-size="default"/>
            Measurement Scale
          </p>
          <b-button
            class="is-primary card-header-icon mt-1 mr-1"
            icon-left="pencil"
            @click="toggleEditMode"
          ><span v-if="this.editMode">End Edit Mode</span>
            <span v-else>Edit Scale</span>
          </b-button>
        </header>
        <div class="card-content">
          <div v-if="subscaleForms">
            <div class="has-text-centered" v-if="editMode">
              <b-button
                @click="createSubscale(0)"
                icon-left="plus"
                :disabled="newScaleDisable"
              >
                Subscale
              </b-button>
            </div>
            <section v-for="(subscale, idx) in subscaleForms">
              <hr v-if="idx === 0"/>
              <div class="mt-4">
                <h1
                  class="title"
                  v-if="!subscale.editable"
                  v-bind:class="{ 'has-text-grey-light': newScaleDisable }"
                >
                  <span v-if="!subscale.editable">{{ subscale.title }}</span>
                  <toggle-edit-button
                    :unlocked="subscale.editable"
                    @toggle-lock="toggleEditSubscale(idx)"
                    :disabled="newScaleDisable"
                  />
                </h1>

                <b-field label="Subscale title" v-else>
                  <b-field>
                    <b-input v-model="subscale.title" expanded></b-input>
                    <p class="control">
                      <toggle-edit-button
                        :unlocked="subscale.editable"
                        @toggle-lock="toggleEditSubscale(idx)"
                      />
                    </p>
                  </b-field>
                </b-field>

                <b-table
                  :loading="!subscaleForms"
                  :striped="true"
                  :hoverable="true"
                  :data="subscaleForms[idx].items"
                >
                  <template slot-scope="props">
                    <b-table-column label="Identifier" field="identifier">
                      <span
                        v-if="!subscale.editable"
                        v-bind:class="{
                          'has-text-grey-light': newScaleDisable
                        }"
                      >{{ props.row.identifier }}</span
                      >
                      <span v-else
                      ><b-input v-model="props.row.identifier"></b-input
                      ></span>
                    </b-table-column>
                    <b-table-column
                      label="Label"
                      field="label"
                      expanded
                      width="100%"
                    >
                      <span
                        v-if="!subscale.editable"
                        v-bind:class="{
                          'has-text-grey-light': newScaleDisable
                        }"
                      >{{ props.row.label }}</span
                      >
                      <span v-else
                      ><b-input v-model="props.row.label"></b-input
                      ></span>
                    </b-table-column>
                    <b-table-column label="Polarity" field="polarity">
                      <b-checkbox
                        :disabled="!subscale.editable"
                        :value="!!props.row.polarity"
                      ></b-checkbox>
                    </b-table-column>
                    <b-table-column label="" field="drag">
                      <span v-bind:class="{ grabbable: subscale.editable }">
                        <b-icon
                          icon="arrow-up-down-bold"
                          :type="{
                            'is-primary': subscale.editable,
                            'is-light': !subscale.editable
                          }"
                        ></b-icon>
                      </span>
                    </b-table-column>
                  </template>
                  <template slot="footer" v-if="editMode">
                    <div class="has-text-centered">
                      <b-button
                        @click="createItem(idx)"
                        icon-left="plus"
                        :disabled="!subscale.editable"
                      >
                        Item
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>
              <hr/>
              <div class="has-text-centered" v-if="editMode">
                <b-button
                  class="mt-3"
                  @click="createSubscale(idx + 1)"
                  icon-left="plus"
                  :disabled="newScaleDisable"
                >
                  Subscale
                </b-button>
              </div>
              <hr/>
            </section>
          </div>
          <app-section-empty v-else is-loading></app-section-empty>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { ApiScales, ApiSurveys } from '@/api-routes.js'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import ToggleEditButton from '@/components/ToggleEditButton'
import EditableInputField from '@/components/elements/form/inputs/EditableInputField'
import { mapActions, mapGetters } from 'vuex'
import Tiles from '@/components/Tiles'
import CategoryPicker from '@/components/measurementscales/CategoryPicker'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import LoadingButton from '@/components/elements/form/LoadingButton'

export default {
  name: 'measurement-detail',
  mixins: [ListTemplateMixin],
  components: {
    LoadingButton,
    ToggleEditButton,
    EditableInputField,
    CategoryPicker,
    Tiles,
    HeroBar,
    CardComponent
  },
  props: {
    scaleId: String
  },
  data () {
    return {
      editMode: false,
      scale_endpoint: ApiScales + 'measurement/' + this.scaleId,
      category_endpoint: ApiScales + 'categories/children/',
      checkBoxPrivate: 'Private',
      categoryData: null,
      scale: null,
      scaleForm: null,
      fieldEditable: {
        title: false,
        authors: false,
        source: false,
        status: false,
        visibility: false,
        category_id: false
      },
      scaleItemsBySubscale: {},
      subscaleForms: [],
      newItemTemplate: {
        scale_id: null,
        subscale_id: null,
        identifier: '',
        label: '',
        polarity: 0
      },
      newSubscaleTemplate: {
        scale_id: null,
        code: '',
        title: '',
        items: [],
        editable: true
      }
    }
  },
  computed: {
    ...mapGetters([
      // 'surveyDetail', - use item
      // 'surveyDivReturnRates', - use items
      'error',
      'item',
      'loading',
      'items'
    ]),
    titleStack () {
      return ['Admin', 'Measurement Scale']
    },
    newScaleDisable () {
      // when editing one subscale, subscale creation must be turned off for now
      return this.subscaleForms.some(sub => sub.editable)
    }
  },
  methods: {
    ...mapActions([
      'fetchItem',
      'fetchItems',
      'saveItem'
    ]),
    createItem (subscale_idx) {
      const newItem = cloneDeep(this.newItemTemplate)
      this.subscaleForms[subscale_idx].items.push(newItem)
    },
    createSubscale (idx) {
      const firstItem = cloneDeep(this.newItemTemplate)
      const newSubscale = cloneDeep(this.newSubscaleTemplate)

      // prepare template copies with correct data and insert
      const scaleId = parseInt(this.scaleId)
      firstItem.scale_id = scaleId
      newSubscale.items.push(firstItem)
      newSubscale.scaleId = scaleId

      if (!this.subscaleForms.hasOwnProperty(idx)) {
        // add to the very end of scale
        this.subscaleForms.push(newSubscale)
      } else {
        this.subscaleForms.splice(idx, 0, newSubscale)
      }
    },
    async getCategoryData (id) {
      const res = await this.fetchItem({
        endpoint: this.category_endpoint + id
      }).catch(error => {
        this.isLoading = false
        if (!null === this.error) {
          this.$toast.danger('error: ' + this.error)
        }
        console.log('ERROR fetchItem: ', error)
      })
      return res.data // TODO: this endpoint has to deliver the data field only
    },
    toggleEdit (elem) {
      // Toggle the lock button next to the field. If it went from editable to non-editable,
      // use the field name to decide which data from updateData to send to the server and commit to store
      this.fieldEditable[elem] = !this.fieldEditable[elem]
      if (!this.fieldEditable[elem]) {
        let payload = {
          method: 'put',
          endpoint: this.scale_endpoint,
          data: { [elem]: this.scaleForm[elem] }
        }
        this.saveItem(payload).then(() => {
          // take the updated data out of the update cache so it doesn't get submitted again
          // TODO: deleting elem is supposed to be done within saveItem
          // method - check !!
          // delete this.updateData[elem]
        })
      }
    },
    toggleEditSubscale (idx) {
      this.subscaleForms[idx].editable = !this.subscaleForms[idx].editable
    },
    toggleEditMode () {
      // used to control the editability of the complete scale, i.e. all subscales and items
      this.editMode = !this.editMode
    },
    async updateCategorySelect (idx, event) {
      if (idx < this.scaleForm.category.length - 1) {
        // one of the parent categories was changed
        // once we have category depths > 2 this will have to be rewritten to be recursive
        this.categoryData = await this.getCategoryData(event.id)
        this.scaleForm.category[idx + 1] = this.categoryData.children_list[0]
        this.scaleForm.category_id = this.categoryData.children_list[0].id
      } else {
        this.scaleForm.category_id = event.id
      }
    },
    r100 () {
      return `${Math.floor(Math.random() * 90 + 10)}%`
    }
  },
  async created () {
    this.scale = await this.fetchItem({ endpoint: this.scale_endpoint }).catch(
      error => {
        this.isLoading = false
        if (!null === this.error) {
          this.$toast.danger('error: ' + this.error)
        }
        console.log('ERROR fetchItem: ', error)
      }
    )
    // console.log('Measurement Scales created ', this.$route.meta.routeName)

    // create an object with all scaleitems referenced by the subscale_id
    // [{subscale_id_01: [ scale_item_01, scale_item_02 ]}, {subscale_id_02: [ … ]}, …]
    this.scaleItemsBySubscale = this.scale.data.scaleitems.reduce((acc, i) => {
      const id = i.subscale_id || -1 // if a scale item is only part of the parent scale, but not a subscale
      acc[id] = (acc[id] || []).concat(i)
      return acc
    }, {})

    this.subscaleForms = this.scale.data.subscales.map(subscale => {
      return {
        ...subscale,
        items: this.scale.data.scaleitems.filter(
          item => item.subscale_id === subscale.id
        ),
        editable: false
      }
    })

    this.categoryData = await this.getCategoryData(this.scale.data.scale_category.parent.id)

    this.scaleForm = this.scale.data
    this.scaleForm['category'] = [this.scale.data.scale_category.parent, this.scale.data.scale_category]
  }
}
</script>

<style>
.grabbable:hover {
  cursor: grab;
}

.grabbable.grabbing {
  cursor: grabbing;
}
</style>
