<template>
  <div>
    <b-dropdown v-if="!scale">
      <button class="button is-primary" type="button" slot="trigger" style="min-width: 20rem;">
        <b-loading :active.sync="loading" :is-full-page="false" :can-cancel="true"/>
      </button>
    </b-dropdown>
    <b-dropdown aria-role="list" v-else>
      <button class="button" slot="trigger" slot-scope="{ active }">
        <span>{{ scale.data.scale_category.parent.name }}</span>
        <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
      </button>
      <b-dropdown-item aria-role="listitem"
                       v-for="(c, idx) in scale.allCategories"
                       :key="idx" :value="c">
        {{ c.name }}
      </b-dropdown-item>
    </b-dropdown>
    <b-icon icon="chevron-right"></b-icon>
    <b-dropdown v-if="!(scale && category)">
      <button class="button is-primary" type="button" slot="trigger" style="min-width: 20rem;">
        <b-loading :active.sync="loading" :is-full-page="false" :can-cancel="true"/>
      </button>
    </b-dropdown>
    <b-dropdown aria-role="list" v-else>
      <button class="button" slot="trigger" slot-scope="{ active }">
        <span>{{ scale.data.scale_category.name }}</span>
        <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
      </button>
      <b-dropdown-item aria-role="listitem"
                       v-for="(c, idx) in category.children_list"
                       :key="idx" :value="c">
        {{ c.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CategoryPicker',
  props: {
    category: {},
    scale: {}
  },
  computed: {
    ...mapGetters([
      'loading',
    ])
  }
}
</script>
