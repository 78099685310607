<template>
  <button
    class="button is-primary is-pulled-right"
    @click="toggleLock"
  >
    <b-icon
      size="is-small"
      :icon="
        unlocked ? 'lock-open-variant-outline' : 'lock-outline'
      "
    />
  </button>
</template>

<script>
export default {
  name: 'ToggleEditButton',
  props: {
    unlocked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleLock() {
      this.$emit('toggle-lock');
    }
  }
}
</script>
