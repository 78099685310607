<template>
  <b-field :label="label" expanded>
    <b-field>
      <b-input
        expanded
        :disabled="!localEditable"
        v-model="localData"
        :icon="icon"
      />
      <p class="control">
        <toggle-edit-button
          :unlocked="localEditable"
          @toggle-lock="toggleEdit()"
        />
      </p>
    </b-field>
  </b-field>
</template>

<script>
import ToggleEditButton from '@/components/ToggleEditButton'
import { mapActions } from 'vuex'

export default {
  name: 'EditableInputField',
  components: {
    ToggleEditButton
  },
  props: {
    data: {
      type: String,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    endpoint: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'pencil'
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // get initial values
      localEditable: this.editable,
      fieldContents: null,
    }
  },

  computed: {
    localData: {
      get () {
        if (null === this.fieldContents) this.fieldContents = this.data
        return this.fieldContents;
      },
      set (newValue) {
        this.fieldContents = newValue
      }
    }
  },

  methods: {
    ...mapActions(['saveItem']),
    toggleEdit () {
      this.localEditable = !this.localEditable
      // emit an event that the parent can handle if it wants to
      this.$emit('update-input-field', this.localData)
      // we will let this component do the API calls for now
      // we might want to move this into the parent sometime…
      if (!this.localEditable && null !== this.endpoint) {
        let payload = {
          method: 'put',
          endpoint: this.endpoint,
          data: { [this.localField]: this.localForm[this.localField] }
        }
        this.saveItem(payload).then(() => {
          //
        })
      }
    }
  }
}
</script>
